
import {Component, Vue} from 'vue-property-decorator';
import CustomPageTitle from "../components/CustomPageTitle.vue";

@Component({
  components: {
    CustomPageTitle
  }
})
export default class Contact extends Vue {
}
